
  /* all songs button */
  /* all songs button */
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
  
  .fancy-button {
    padding-top: 1px;
    padding-bottom:5px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.2rem;
    color: #ffffff;
    background: linear-gradient(90deg, #4ca592, #5bd8bd); /* Gradient background */
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 6px 20px rgba(91, 216, 189, 0.5); /* Soft shadow effect */
  }
  
  .fancy-button:hover {
    background: linear-gradient(90deg, #5bd8bd, #4ca592); /* Reverse gradient on hover */
    transform: scale(1.05); /* Slight zoom effect */
    box-shadow: 0 6px 20px rgba(91, 216, 189, 0.5);
  }
  



/* for load more */
  .fancy-button2 {
    padding-top: 0px;
    padding-bottom:4px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: .91rem;
    color: #ffffff;
    background: linear-gradient(90deg, #4ca592, #5bd8bd); /* Gradient background */
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(91, 216, 189, 0.5); /* Soft shadow effect */
  }