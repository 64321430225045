
/* Container for the slider */


.slider-container {
  overflow: auto;
  width: 100%;
  scrollbar-width: thin;
  scrollbar-color: #333 #f0f0f0;
}

.slider-container::-webkit-scrollbar {
  height: 6px; /* Adjust the height to your liking */
}

.slider-container::-webkit-scrollbar-thumb {
  background-color: #666; /* Change the color to your liking */
  border-radius: 3px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.slider-container::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Change the color to your liking */
  border-radius: 3px;
}

/* Slider for continuous, circular scrolling */
.slider {
  display: flex;
  gap: 2rem; /* Space between images */
  animation: slideCircular 100s linear infinite; /* Smooth, continuous slide */
}

/* Keyframes for circular, infinite scroll */
@keyframes slideCircular {
  0% { transform: translateX(0); }
  100% { transform: translateX(calc(-100% - 2rem)); } /* Slides by full width plus gap */
}

/* Individual slide styling */
.slide {
  flex: 0 0 auto;
  width: 15rem; /* Adjust width of each image container */
}

/* Info box styling */
.info-box {
  height: 5rem;
  width: 15rem;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0 0 1.5rem 1.5rem;
  color: white;
  padding: 1.25rem;
  text-align: center;
}















.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background: #fff;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  text-align: center;
  position: relative;
}

.close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ff4040;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 16px;
  cursor: pointer;
}

.popup-content ul {
  list-style: none;
  padding: 0;
}





