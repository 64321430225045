/* Container with fixed height - adjusts based on image height */
.h-eddh {
   
    overflow: hidden;
    position: relative;
  }



/* Flex container for images with vertical gap */
.slider-content {
    display: flex;
    flex-direction: column;
    gap: 1rem; /* Space between images */
    animation: slideUp 15s linear infinite;
  }
  
  /* Smooth, infinite vertical slide animation */
  @keyframes slideUp {
    0% { transform: translateY(0); }
    100% { transform: translateY(-50%); } /* Adjust to half the slider-content's total height */
  }