.song-list {
  padding: 20px;
  background-color: #f9f9f9;
}

.search-form {
  display: flex;
  margin-bottom: 20px;
}

.search-input, .filter-input {
  flex: 1;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
}

.search-button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
}

.songs-container {
  display: flex;
  flex-wrap: wrap;
}

.song-card {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  margin: 10px;
  cursor: pointer;
  width: calc(33.333% - 20px); /* Adjust as needed */
}

.loading, .load-more-button {
  text-align: center;
  margin: 20px 0;
}


.lyric-item {
  color: gray; /* Default color for lyrics */
}






.lyric-line {
  transition: background-color 0.3s ease;
}
















/* styles/Screen.css */

.lyrics-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh; /* Full viewport height */
  
  color: white; /* White text color */
  text-align: center;
}



.audio-player {
  margin-bottom: 20px; /* Space below the audio player */
}




.lyrics-container {
  width: 90%;
  max-width: 600px;
  height: 60vh; /* Adjust the height to simulate a video frame */
  overflow-y: hidden;
  /* background-color: rgba(0, 0, 0, 0.7); Semi-transparent dark background */
  background-image: url('../images/bg.jpg'); /* Path to your background image */
  background-size: cover;
  background-position: center;

  border-radius: 15px; /* Rounded corners */
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); /* Soft shadow */
  position: relative;
  display: flex;
  flex-direction: column;
}

.lyric-line {
  margin: 0;
  font-size: 1.5em; /* Adjust font size as needed */
  line-height: 1.8; /* Add line height for better spacing */
  transition: color 0.3s;
}



.lyric-line.active {
  color: #22f522; /* Or any color that indicates active state */
}

